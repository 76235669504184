import * as Log from "../../_snowpack/link/packages/frontend/common/logging.js";
import amplitudeLib from "../../_snowpack/pkg/amplitude-js.js";
import mixpanel from "../../_snowpack/pkg/mixpanel-browser.js";
import config from "../../_snowpack/link/packages/frontend/common/config.js";
import env from "../../_snowpack/link/packages/frontend/common/env.js";
let enabled = true;
const amplitude = amplitudeLib.getInstance();
amplitude.init(config.amplitudeKey, void 0, {
  apiEndpoint: `${config.apiGateway}/amplitude`
});
amplitude.setVersionName(env.APP_VERSION.toString());
mixpanel.init(config.mixpanelKey, {
  api_host: "https://proxy-jq67vrqmkq-uc.a.run.app",
  ignore_dnt: true
});
export const logEvent = (name, data) => {
  Log.debug("analytic event", {name, data});
  if (!enabled || env.NODE_ENV === "development") {
    return;
  }
  amplitude.logEvent(name, data);
  mixpanel.track(name, data);
};
export const setOptOut = (settings) => {
  const newEnabled = settings ? settings.analyticsEnabled : true;
  if (newEnabled === enabled) {
    return;
  }
  enabled = newEnabled;
  amplitude.setOptOut(!enabled);
  if (enabled) {
    mixpanel.opt_in_tracking();
  } else {
    mixpanel.opt_out_tracking();
  }
  Log.debug("Analytics.setOptOut set to", !enabled);
};
export const setUserProperties = (userId, email) => {
  amplitude.setUserId(userId);
  amplitude.setUserProperties({email, hash: env.COMMIT_HASH});
  mixpanel.identify(userId);
  mixpanel.people.set({
    $email: email,
    userId,
    version: env.APP_VERSION,
    hash: env.COMMIT_HASH
  });
  Log.debug(`Analytics User Id set: ${userId}`);
};
