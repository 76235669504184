import {firebase} from "../../../../_snowpack/link/packages/frontend/common/firebase.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
import {result} from "../../../../_snowpack/link/packages/utils/src/index.js";
export const subscribe = (elmApp) => {
  elmApp.ports.uploadAttachments.subscribe(({noteId, files}) => {
    const errorCallback = (msg) => elmApp.ports.uploadStatus.send(result.err({msg}));
    if (files.length === 0) {
      return;
    }
    if (files.length > 1) {
      errorCallback("One file at a time, please.");
      return;
    }
    const file = files[0];
    if (!file) {
      errorCallback("Error uploading.");
      return;
    }
    uploadFile(elmApp, noteId, false)(file);
  });
  elmApp.ports.downloadAttachment.subscribe(({downloadUrl, filename}) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => saveFile(filename, xhr.response);
    xhr.open("GET", downloadUrl);
    xhr.send();
  });
  elmApp.ports.cancelUpload.subscribe((canceller) => {
    try {
      canceller();
      elmApp.ports.uploadStatus.send(result.err({msg: "Upload cancelled."}));
    } catch (err) {
      Log.warning("failed to cancel attachment upload", err);
    }
  });
  elmApp.ports.viewAttachment.subscribe(async (pathInStorage) => {
    firebase.storage().ref(pathInStorage).getDownloadURL().then((url) => {
      window.open(url, "_blank");
    }).catch((err) => {
      Log.error("failed to viewAttachment", {pathInStorage, err});
    });
  });
};
export const uploadFile = (elmApp, noteId, inline) => async (file) => {
  const callback = elmApp.ports.uploadStatus.send;
  const errorCallback = (msg) => callback(result.err({msg}));
  const userId = firebase.auth().currentUser?.uid;
  if (!userId)
    throw Error("Expected firebase.auth().currentUser.uid");
  if (!window.navigator.onLine) {
    errorCallback("Network down.");
    throw Error("Network down.");
  }
  if (file.type === "") {
    errorCallback("Folders not supported.");
    throw Error("Folders not supported.");
  }
  if (file.size >= 50 * 1024 * 1024) {
    errorCallback("Limited to 50 MB.");
    throw Error("Limited to 50 MB.");
  }
  const uploadTask = firebase.storage().ref().child(userId).child(noteId).child("attachments").child(uniquifyFileName(file.name)).put(file);
  const storagePath = uploadTask.snapshot.ref.fullPath;
  const snapshotToRawUploadStatus = (snapshot, downloadUrl2) => ({
    entityId: noteId,
    bytesTransferred: snapshot.bytesTransferred,
    totalBytes: snapshot.totalBytes,
    storagePath,
    downloadUrl: downloadUrl2,
    canceller: snapshot.task.cancel,
    fileName: file.name,
    mimeType: file.type,
    inline
  });
  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
    const rawUploadStatus = snapshotToRawUploadStatus(snapshot, null);
    if (snapshot.totalBytes === snapshot.bytesTransferred) {
      return;
    }
    callback(result.ok(rawUploadStatus));
  });
  let downloadUrl;
  await uploadTask.then(async (snapshot) => {
    downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
    const rawUploadStatus = snapshotToRawUploadStatus(snapshot, downloadUrl);
    callback(result.ok(rawUploadStatus));
    Log.debug("attachment upload successful", rawUploadStatus);
  }).catch((err) => {
    if (err.code === "storage/canceled")
      return;
    Log.warning("error uploading attachment to note", {
      err,
      userId,
      noteId,
      fileName: file.name
    });
    callback(result.err({
      msg: "Error uploading.",
      upload: {entityId: noteId, storagePath}
    }));
  });
  if (!downloadUrl)
    throw Error("Upload failed");
  return downloadUrl;
};
const saveFile = (filename, blob) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    a.remove();
  }, 0);
  return;
};
const uniquifyFileName = (fullFileName) => {
  const index = fullFileName.lastIndexOf(".");
  if (index == -1)
    return fullFileName;
  const name = fullFileName.slice(0, index);
  const extension = fullFileName.slice(index);
  return `${name}-${Date.now()}${extension}`;
};
