import React, {useCallback, useMemo, useState} from "../../../../../pkg/react.js";
import {Transforms, Editor} from "../../../../../pkg/slate.js";
import {Slate, Editable, ReactEditor} from "../../../../../pkg/slate-react.js";
import lodash from "../../../../../pkg/lodash.js";
import * as SearcbarEditor from "./index.js";
import {Leaf, Element} from "../components/index.js";
import * as query from "../query.js";
import {editorStyling} from "./styling.js";
import {generatePlaceholder} from "./placeholder.js";
export const Component = (props) => {
  const editor = useMemo(() => SearcbarEditor.init(), []);
  const [palette, setPalette] = useState(props.palette);
  const [slateDoc, setSlateDoc] = useState(query.toSlateDoc(props.initialSearchbarQuery));
  const onChange = useCallback((newSlateDoc) => {
    if (lodash.isEqual(newSlateDoc, slateDoc))
      return;
    setSlateDoc(newSlateDoc);
    props.onChange(query.fromSlateDoc(editor.children));
  }, [slateDoc, editor]);
  const setSearchbarQuery = useCallback((searchbarQuery) => {
    const newSlateDoc = query.toSlateDoc(searchbarQuery);
    Editor.withoutNormalizing(editor, () => {
      Transforms.removeNodes(editor, {at: [0]});
      Transforms.insertNodes(editor, newSlateDoc, {at: [0]});
    });
    Transforms.select(editor, Editor.end(editor, []));
  }, [editor]);
  const focusSearchbar = useCallback(() => {
    ReactEditor.focus(editor);
    setTimeout(() => Transforms.select(editor, Editor.end(editor, [])), 15);
  }, []);
  const blurSearchbar = useCallback(() => {
    ReactEditor.blur(editor);
  }, []);
  props.onInit({
    setSearchbarQuery,
    setPalette,
    focusSearchbar,
    blurSearchbar
  });
  const placeholder = useMemo(generatePlaceholder, []);
  return /* @__PURE__ */ React.createElement(Slate, {
    editor,
    value: slateDoc,
    onChange
  }, /* @__PURE__ */ React.createElement(Editable, {
    className: editorStyling,
    renderLeaf: Leaf,
    renderElement: Element(palette),
    placeholder
  }));
};
