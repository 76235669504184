import * as RRuleUtil from "../../util/rrule.js";
import * as RRule from "../../../../_snowpack/pkg/rrule.js";
import {DateTime} from "../../../../_snowpack/pkg/luxon.js";
export const subscribe = (elmApp) => {
  elmApp.ports.requestRRuleJSInstances.subscribe(({windowStart, windowEnd, eventData}) => {
    const getOccurrences = (event) => {
      const rrules = event.rrules.concat([RRuleUtil.toDTSTART(event.dtStart, event.mTimeZone)]).join("\n");
      const [start, end] = [
        RRuleUtil.toUTC(windowStart),
        RRuleUtil.toUTC(windowEnd)
      ];
      return RRule.rrulestr(rrules).between(start, end, true).map((date) => {
        if (event.mTimeZone === "UTC") {
          return date.getTime();
        } else {
          return DateTime.fromJSDate(date).toUTC().setZone("local", {keepLocalTime: true}).toMillis();
        }
      });
    };
    const eventDateTimes = eventData.map((event) => [
      event.id,
      getOccurrences(event)
    ]);
    setTimeout(() => elmApp.ports.receiveRRuleJSInstances.send(eventDateTimes), 5);
  });
};
