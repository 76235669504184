import {noOp} from "../util.js";
export const withSingleline = (editor) => {
  const {insertFragment} = editor;
  editor.insertBreak = noOp;
  editor.insertFragment = (nodes) => {
    insertFragment([nodes[0]]);
    return;
  };
  return editor;
};
