import {authProvider, firebase} from "../../../../_snowpack/link/packages/frontend/common/firebase.js";
import firestoreUtil from "../../../../_snowpack/link/packages/frontend/common/firestore.js";
import * as Browser from "../../util/browser.js";
export let redirectTo = {type: "landing"};
export const subscribe = (elmApp) => {
  elmApp.ports.signIn.subscribe(() => {
    void firebase.auth().signInWithRedirect(authProvider());
  });
  elmApp.ports.signOut_.subscribe(signOutHelper);
  elmApp.ports.switchAccount.subscribe(({email}) => {
    const authProvider2 = new firebase.auth.GoogleAuthProvider();
    if (email) {
      authProvider2.setCustomParameters({login_hint: email});
    } else {
      authProvider2.setCustomParameters({
        prompt: "select_account"
      });
    }
    void firebase.auth().signInWithRedirect(authProvider2);
  });
};
export const handleSignoutRedirect = () => {
  switch (redirectTo.type) {
    case "landing":
      Browser.routeToLandingPage() || location.assign(location.origin);
      break;
    case "home":
      location.assign(location.origin);
      break;
    case "internalRoute":
      location.assign(location.origin + redirectTo.path);
      break;
  }
};
export const signOutHelper = (redirectTo_) => {
  firestoreUtil.unsubscribeAll();
  redirectTo = redirectTo_;
  firebase.auth().signOut();
};
