import _ from "../../../_snowpack/pkg/lodash.js";
import {promiseUtils} from "../../../_snowpack/link/packages/utils/src/index.js";
import * as Log from "../../../_snowpack/link/packages/frontend/common/logging.js";
const batchProcessor = async ({
  tag,
  items,
  sendBatch,
  maxBatchSize,
  delayMillis,
  processItem = _.identity,
  fromCache = false
}) => {
  let acc = [];
  let remaining = items.length;
  const total = items.length;
  const addToBatch = (item) => {
    try {
      acc.push(processItem(item));
    } catch (err) {
      Log.error("Error processing batch item", {err, loc: "batchProcessor"});
    }
    remaining--;
  };
  for (const item of items) {
    if (acc.length < maxBatchSize) {
      addToBatch(item);
    } else {
      const batch = {items: acc, remaining, total, tag, fromCache};
      sendBatch(batch);
      Log.debug("Batch sent", batch);
      acc = [];
      addToBatch(item);
      await promiseUtils.pause(delayMillis);
    }
  }
  if (acc.length > 0) {
    const batch = {items: acc, remaining, total, tag, fromCache};
    sendBatch(batch);
    Log.debug("Batch sent", batch);
  }
};
export default batchProcessor;
