import {Element, Text} from "../../../../pkg/slate.js";
import {filterMap} from "../../utils/src/index.js";
const queryElementToDescendants = (queryElement) => {
  switch (queryElement.type) {
    case "text":
      return [{text: queryElement.text}];
    case "event":
      return [
        {
          type: "search-bar-chip",
          data: {
            type: "event-chip",
            eventId: queryElement.eventId,
            eventTitle: queryElement.eventTitle
          },
          children: [{text: ""}]
        },
        {text: " "}
      ];
    case "person":
      return [
        {
          type: "search-bar-chip",
          data: {
            type: "person-chip",
            personId: queryElement.personId,
            personName: queryElement.personName
          },
          children: [{text: ""}]
        },
        {text: " "}
      ];
  }
};
export const toSlateDoc = (query) => {
  const children = query.map(queryElementToDescendants).flat();
  return [
    {type: "p", children: children.length === 0 ? [{text: ""}] : children}
  ];
};
export const fromSlateDoc = (slateDoc) => {
  const firstLine = slateDoc[0];
  if (!Element.isElement(firstLine)) {
    console.error("expected one line of input");
    return [];
  }
  return filterMap(firstLine.children, (node_) => {
    const node = node_;
    if (Text.isText(node)) {
      return {type: "text", text: node.text};
    }
    if (node.type === "search-bar-chip") {
      const chipData = node.data;
      if (chipData.type == "event-chip") {
        return {
          type: "event",
          eventTitle: chipData.eventTitle,
          eventId: chipData.eventId
        };
      } else {
        return {
          type: "person",
          personName: chipData.personName,
          personId: chipData.personId
        };
      }
    }
    return void 0;
  });
};
