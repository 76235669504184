import {Elm} from "../elm/Main.js";
import * as Subscriptions from "./elm/subscriptions.js";
import * as Log from "../../_snowpack/link/packages/frontend/common/logging.js";
import {firestore} from "../../_snowpack/link/packages/frontend/common/firebase.js";
import ElmFirestore from "../../_snowpack/link/packages/frontend/lib/elm-firestore/lib/index.js";
import * as Settings from "./util/settings.js";
import * as Analytics from "./analytics.js";
import env from "../../_snowpack/link/packages/frontend/common/env.js";
import * as initialLoadWindow from "./util/initialLoadWindow.js";
export const init = () => {
  const offset = new Date().getTimezoneOffset();
  let timeZone;
  try {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    Log.debug("current IANA time zone:", timeZone);
  } catch (err) {
    timeZone = null;
    Log.warning("TimeZone Error: NoZoneName", err);
  }
  const userSettings = Settings.loadFromLocalStorage();
  Analytics.setOptOut(userSettings);
  const elmFlags = {
    currentTimeMillis: Date.now(),
    timeZoneOffset: -offset,
    timeZone,
    userSettings,
    appVersion: env.APP_VERSION,
    initialLoadWindow: initialLoadWindow.millis,
    isChrome: !!window.chrome,
    uidEntropy: Array.from(crypto.getRandomValues(new Uint32Array(4)))
  };
  const elmApp = Elm.Main.init({flags: elmFlags});
  const elmFirestore = ElmFirestore.init({
    firestore,
    fromElm: elmApp.ports.toFirestore,
    toElm: elmApp.ports.fromFirestore,
    debug: Log.debugEnabled && env.NODE_ENV !== "production"
  });
  Log.debug("ElmFirestore initialized");
  Subscriptions.init(elmApp, elmFirestore);
  Log.debug("Elm initialized");
  return elmApp;
};
