import {firestore} from "../../../../_snowpack/link/packages/frontend/common/firebase.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
import {handleFirestoreError} from "../util.js";
import firestoreUtil from "../../../../_snowpack/link/packages/frontend/common/firestore.js";
export const watch = (elmApp) => {
  Log.debug("appVersion subscribed");
  const docName = window.location.hostname === "next.witful.com" ? "next" : "web";
  const appVersionRef = firestore.collection("_releases").doc(docName);
  firestoreUtil.onDocumentSnapshot(appVersionRef, {
    next: (appVersion) => {
      elmApp.ports.appVersion.send(appVersion.data());
      Log.debug("appVersion snapshot updated");
    },
    error: (err) => {
      handleFirestoreError(elmApp, appVersionRef.path, err);
      Log.error("appVersion.onSnapshot error", {
        documentPath: appVersionRef.path,
        err
      });
    }
  });
};
