import {css} from "../../../../../pkg/@emotion/css.js";
const baseEditorStyling = css`
  padding: 7px 0px;
`;
const hideScrollbars = css`
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const singleline = css`
  white-space: pre !important;
  overflow-x: scroll;
`;
export const editorStyling = [
  hideScrollbars,
  singleline,
  baseEditorStyling
].join(" ");
