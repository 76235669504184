import {loadStripe} from "../../../../_snowpack/pkg/@stripe/stripe-js.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
const STRIPE_PUBLISHABLE_KEY = function() {
  if (document.location.hostname == "app.witful.com" || document.location.hostname == "next.witful.com") {
    return "pk_live_e8RLNui98VSPDHcFs4lFCjkK00xBj2E2Yi";
  }
  return "pk_test_d7uiyNJynmL5TEj8YwUrThuX00fl2dpDsP";
}();
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
export const subscribe = (elmApp) => {
  elmApp.ports.stripeRedirectToSessionById.subscribe(async (sessionId) => {
    const stripe = await stripePromise;
    Log.assert(!!stripe, "Unable to load Stripe.");
    await stripe?.redirectToCheckout({sessionId});
  });
};
