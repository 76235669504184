import * as Analytics from "./ports/analytics.js";
import * as Auth from "./ports/auth.js";
import * as Browser from "./ports/browser.js";
import * as GCal from "./ports/gcal.js";
import * as Logging from "./ports/logging.js";
import * as RRule from "./ports/rrule.js";
import * as Stripe from "./ports/stripe.js";
import * as User from "./ports/user.js";
import * as Attachments from "./ports/attachments.js";
import * as SearchIndices from "./ports/search-indices.js";
import * as SearchWeightIndices from "./ports/search-weight-indices.js";
import * as Settings from "./ports/settings.js";
import * as Person from "./ports/person.js";
import * as ElmFirestore from "./ports/elm-firestore.js";
import * as ActionItems from "./ports/action-items.js";
export const init = (elmApp, elmFirestore) => {
  ActionItems.subscribe(elmApp);
  Analytics.subscribe(elmApp);
  Auth.subscribe(elmApp);
  Browser.subscribe(elmApp);
  GCal.subscribe(elmApp);
  Logging.subscribe(elmApp);
  RRule.subscribe(elmApp);
  Stripe.subscribe(elmApp);
  User.subscribe(elmApp);
  Person.subscribe(elmApp);
  Attachments.subscribe(elmApp);
  ElmFirestore.subscribe(elmApp, elmFirestore);
  SearchIndices.subscribe(elmApp);
  SearchWeightIndices.subscribe(elmApp);
  Settings.subscribe(elmApp);
};
