import {DateTime} from "../../../_snowpack/pkg/luxon.js";
function padLeft(item, targetLength, padString = " ") {
  const item_ = String(item);
  if (item_.length >= targetLength)
    return item_;
  return padLeft(padString + item_, targetLength, padString);
}
export const toDTSTART = (posix, mTimezone) => {
  const timezone = mTimezone ? mTimezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = DateTime.fromMillis(posix).setZone(timezone);
  const startTime = [
    padLeft(date.year, 4, "0"),
    padLeft(date.month, 2, "0"),
    padLeft(date.day, 2, "0"),
    "T",
    padLeft(date.hour, 2, "0"),
    padLeft(date.minute, 2, "0"),
    padLeft(date.second, 2, "0")
  ].join("");
  return "DTSTART;TZID=" + timezone + ":" + startTime + ";";
};
export const toUTC = (posix) => DateTime.fromMillis(posix).setZone("utc", {keepLocalTime: true}).toJSDate();
