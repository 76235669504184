import * as logging from "../../../../_snowpack/link/packages/frontend/common/logging.js";
import {firebase, firestore} from "../../../../_snowpack/link/packages/frontend/common/firebase.js";
import {contentToSlateDoc} from "../../../../_snowpack/link/packages/editor-element/src/customElement.js";
import * as slate from "../../../../_snowpack/pkg/slate.js";
import {getEditorPlainText} from "../../../../_snowpack/link/packages/editor/src/index.js";
import {Editor as witfulEditor} from "../../../../_snowpack/link/packages/editor/src/index.js";
import * as _ from "../../../../_snowpack/pkg/lodash.js";
export const subscribe = (elmApp) => {
  elmApp.ports.mergePersons.subscribe(async (ids) => {
    const userId = firebase.auth().currentUser?.uid;
    if (userId) {
      await mergePersonsInDatabase(elmApp, userId, ids.primaryPersonId, ids.secondaryPersonId);
    } else {
      logging.error("attempt to merge persons by signed-out user", {
        userId,
        ids
      });
    }
  });
};
const isMergeable = (person) => {
  return !person.deleted && !person.mergedInto;
};
export const mergePersons = (primary, secondary) => {
  const primarySlateDoc = contentToSlateDoc({
    data: primary.slateDoc,
    type: "slateDoc"
  }, logging);
  const secondarySlateDoc = contentToSlateDoc({
    data: secondary.slateDoc,
    type: "slateDoc"
  }, logging);
  const resultDoc = [].concat(primarySlateDoc).concat(secondarySlateDoc);
  let resultPlaintext;
  try {
    const editor = witfulEditor.init();
    slate.Transforms.insertNodes(editor, resultDoc);
    resultPlaintext = getEditorPlainText(editor);
  } catch (err) {
    logging.error("mergePersons: error computing resultPlaintext from slateDoc; falling back to bio fields", {err});
    resultPlaintext = `${primary.bio} ${secondary.bio}`;
  }
  return {
    name: primary.name,
    bio: resultPlaintext,
    slateDoc: JSON.stringify(resultDoc),
    modified: primary.modified || "",
    createdAt: primary.createdAt || firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: primary.createdBy || secondary.createdBy || null,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    emailAddresses: _.uniq([].concat(primary.emailAddresses, secondary.emailAddresses)),
    ordering: (primary.ordering || 0) + 1,
    schemaVersion: primary.schemaVersion || 4,
    phoneNumbers: primary.phoneNumbers || [],
    mergedInto: null,
    deleted: false,
    notDuplicateOf: [].concat(primary.notDuplicateOf || [], secondary.notDuplicateOf || [])
  };
};
const mergePersonsInDatabase = async (elmApp, userId, primaryPersonId, secondaryPersonId) => {
  logging.debug(`Merging persons`, {
    userId,
    primaryPersonId,
    secondaryPersonId
  });
  const userRef = firestore.collection("accounts").doc(userId);
  const peopleCollection = userRef.collection("people");
  const primaryPersonRef = peopleCollection.doc(primaryPersonId);
  const secondaryPersonRef = peopleCollection.doc(secondaryPersonId);
  firestore.runTransaction(async (transaction) => {
    const primaryPerson = await transaction.get(primaryPersonRef);
    const secondaryPerson = await transaction.get(secondaryPersonRef);
    const primaryPersonData = primaryPerson.data();
    const secondaryPersonData = secondaryPerson.data();
    if (!primaryPersonData || !secondaryPersonData) {
      logging.debug(`Persons not found for mergePersons`, {
        userId,
        primaryPersonId,
        secondaryPersonId
      });
      return {success: false, primaryPersonId, secondaryPersonId};
    }
    if (!isMergeable(primaryPersonData) || !isMergeable(secondaryPersonData)) {
      logging.error(`Attempt to merge persons that can't be merged`, {
        userId,
        primaryPersonId,
        secondaryPersonId
      });
      return {success: false, primaryPersonId, secondaryPersonId};
    }
    const mergedPerson = mergePersons(primaryPersonData, secondaryPersonData);
    transaction.update(primaryPersonRef, mergedPerson);
    transaction.update(secondaryPersonRef, {
      deleted: true,
      mergedInto: primaryPersonId,
      ordering: (secondaryPersonData.ordering || 0) + 1,
      schemaVersion: secondaryPersonData.schemaVersion || 4
    });
    return {success: true, primaryPersonId, secondaryPersonId};
  }).then((outcome) => elmApp.ports.personsMerged.send(outcome)).catch((err) => {
    logging.error("error during execution of person merge firestore transaction", {err, userId, primaryPersonId, secondaryPersonId});
    elmApp.ports.personsMerged.send({
      success: false,
      primaryPersonId,
      secondaryPersonId
    });
  });
};
