import config from "../../../../_snowpack/link/packages/frontend/common/config.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
import {firebase} from "../../../../_snowpack/link/packages/frontend/common/firebase.js";
import * as User from "./user.js";
import {handleFirestoreError} from "../util.js";
import firestoreUtil from "../../../../_snowpack/link/packages/frontend/common/firestore.js";
import batchProcessor from "../../util/batchProcessor.js";
import * as initialLoadWindow from "../../util/initialLoadWindow.js";
import {DateTime} from "../../../../_snowpack/pkg/luxon.js";
export const subscribe = (elmApp) => {
  elmApp.ports.linkCalendar.subscribe(async () => {
    Log.debug("offline calendar requested");
    const user = firebase.auth().currentUser;
    if (!user) {
      Log.error("Expected user to be logged in", {loc: "linkCalendar"});
      return;
    }
    window.gapi.load("auth2", () => {
      window.gapi.auth2.init({
        client_id: config.firebase.clientId
      }).then(async (googleAuth) => {
        const userData = (await User.toAccountRef(user.uid).get()).data();
        if (userData?.tokens && !userData?.tokens?.refresh_token) {
          googleAuth.disconnect();
        }
        googleAuth.grantOfflineAccess({
          scope: config.firebase.scopes.join(" "),
          prompt: "consent",
          ux_mode: "redirect",
          redirect_uri: `${window.location.origin}/auth`,
          login_hint: user.email
        });
      }).catch((err) => {
        Log.error("gcal: error granting offline access", {
          loc: "linkCalendar",
          err
        });
      });
    });
  });
  elmApp.ports.watchCalendarEvents.subscribe(async (userId) => {
    const gcalEventsRef = User.toAccountRef(userId).collection("google-calendar-events/primary/events");
    Log.debug("Loading recent calendar events from cache");
    const [
      recurringEvents,
      recentEvents,
      recentAllDayEvents
    ] = await Promise.all([
      gcalEventsRef.where("recurrence", "!=", null).get(),
      gcalEventsRef.where("start.dateTime", ">=", initialLoadWindow.datetime.start).where("start.dateTime", "<=", initialLoadWindow.datetime.end).get(),
      gcalEventsRef.where("start.date", ">=", initialLoadWindow.date.start).where("start.date", "<=", initialLoadWindow.date.end).get()
    ]);
    const items = recentEvents.docs.concat(recentAllDayEvents.docs).concat(recurringEvents.docs).map((doc) => doc.data());
    elmApp.ports.gcalEvents.send({
      tag: "initial-events",
      items,
      remaining: 0,
      total: items.length,
      fromCache: false
    });
    Log.debug("Loaded recent calendar events from cache");
    firestoreUtil.onCollectionSnapshot(gcalEventsRef, {
      next: (snapshot) => {
        batchProcessor({
          tag: "all-events",
          items: snapshot.docChanges(),
          sendBatch: elmApp.ports.gcalEvents.send,
          maxBatchSize: 100,
          delayMillis: 250,
          processItem: (change) => {
            const value = change.doc.data();
            if (change.type === "removed") {
              value.updated = DateTime.fromISO(value.updated).plus(1).toISO();
              value.status = "cancelled";
            }
            return value;
          },
          fromCache: snapshot.metadata.fromCache
        });
      },
      error: (err) => {
        handleFirestoreError(elmApp, gcalEventsRef.path, err);
        Log.error("gcalEvents.onSnapshot error", {
          collectionPath: gcalEventsRef.path,
          err
        });
      }
    });
    Log.debug("Listening to all calendar events");
  });
};
