import {DateTime} from "../../../_snowpack/pkg/luxon.js";
const oneMonthWindow = (format, now_) => {
  const now2 = now_.minus({milliseconds: now_.millisecond});
  const toISO = (date2) => format == "date" ? date2.toISODate() : date2.toISO({suppressMilliseconds: true});
  return {
    start: toISO(now2.minus({days: 15}).startOf("week")),
    end: toISO(now2.plus({days: 15}).endOf("week").plus({milliseconds: 1}))
  };
};
const now = DateTime.now();
export const datetime = oneMonthWindow("datetime", now);
export const date = oneMonthWindow("date", now);
export const millis = {
  start: new Date(datetime.start).getTime(),
  end: new Date(datetime.end).getTime()
};
