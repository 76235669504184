import config from "../../../_snowpack/link/packages/frontend/common/config.js";
import * as Log from "../../../_snowpack/link/packages/frontend/common/logging.js";
const settingsLocalStorageKey = `${config.firebase.projectId}/settings`;
export const saveToLocalStorage = (settings) => {
  window.localStorage.setItem(settingsLocalStorageKey, JSON.stringify(settings));
  Log.debug("Settings saved", {settings});
};
export const clearSettingsFromLocalStorage = () => {
  window.localStorage.setItem(settingsLocalStorageKey, "{}");
};
export const loadFromLocalStorage = () => {
  const rawSettings = window.localStorage.getItem(settingsLocalStorageKey);
  Log.debug("Settings loaded", {rawSettings});
  if (!rawSettings)
    return null;
  let settings = null;
  try {
    settings = JSON.parse(rawSettings);
  } catch (err) {
    Log.error("Error Parsing Settings (localStorage)", {err, rawSettings});
  }
  return settings;
};
