import {withReact} from "../../../../../pkg/slate-react.js";
import {withHistory} from "../../../../../pkg/slate-history.js";
import {createEditor} from "../../../../../pkg/slate.js";
import {withSingleline, withChips} from "../modules/index.js";
const defaultModules = () => [
  withReact,
  withHistory,
  withSingleline,
  withChips
];
export const init = (editor = createEditor()) => defaultModules().reduce((acc, module) => module(acc), editor);
