import firestoreUtils from "../../../../_snowpack/link/packages/frontend/common/firestore.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
export const subscribe = (elmApp, elmFirestore) => {
  elmApp.ports.initTimestampHooks.subscribe(({collectionPaths}) => {
    collectionPaths.forEach((collectionPath) => {
      const elmTimestampToFirestoreTimestamp = (doc) => firestoreUtils.fieldCreatedAt(firestoreUtils.fieldUpdatedAt(doc.data));
      elmFirestore.setHook({
        path: collectionPath,
        event: "create",
        op: "formatData",
        hook: elmTimestampToFirestoreTimestamp
      });
      elmFirestore.setHook({
        path: collectionPath,
        event: "update",
        op: "formatData",
        hook: elmTimestampToFirestoreTimestamp
      });
    });
    Log.debug("elm-firestore hooks initialized", {collectionPaths});
  });
};
