var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorate = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import {FASTElement, customElement, attr} from "../../../../pkg/@microsoft/fast-element.js";
import React from "../../../../pkg/react.js";
import ReactDOM from "../../../../pkg/react-dom/client.js";
import * as SearchbarEditor from "./editor/index.js";
import {noOp} from "./util.js";
export const init = (setSearchbarQueryFromElm) => {
  let WitfulSearchBar = class extends FASTElement {
    constructor() {
      super(...arguments);
      this.searchbarQuery = "[]";
      this.setPalette = void 0;
      this.setSearchbarQuery = void 0;
      this.reactRoot = ReactDOM.createRoot(this);
      this.focus = noOp;
      this.blur = noOp;
    }
    connectedCallback() {
      super.connectedCallback();
      setSearchbarQueryFromElm((query) => {
        this.setSearchbarQuery?.(query);
      });
      const setCallbacks = (callbacks) => {
        this.setSearchbarQuery = callbacks.setSearchbarQuery;
        this.setPalette = callbacks.setPalette;
        this.focus = callbacks.focusSearchbar;
        this.blur = callbacks.blurSearchbar;
      };
      const palette = this.palette ? JSON.parse(this.palette) : null;
      this.reactRoot.render(/* @__PURE__ */ React.createElement(SearchbarEditor.Component, {
        initialSearchbarQuery: JSON.parse(this.searchbarQuery),
        palette,
        onChange: (newQuery) => this.onChange(newQuery),
        onInit: (callbacks) => setCallbacks(callbacks)
      }));
    }
    disconnectedCallback() {
      super.disconnectedCallback();
      this.reactRoot.unmount();
    }
    paletteChanged(_, newPalette) {
      this.setPalette?.(JSON.parse(newPalette));
    }
    searchbarQueryChanged(currentQuery, newQuery) {
      if (currentQuery !== void 0)
        return;
      this.setSearchbarQuery?.(JSON.parse(newQuery));
    }
    onChange(newQuery) {
      this.$emit("searchQueryChanged", newQuery);
    }
  };
  __decorate([
    attr()
  ], WitfulSearchBar.prototype, "searchbarQuery", 2);
  __decorate([
    attr()
  ], WitfulSearchBar.prototype, "palette", 2);
  WitfulSearchBar = __decorate([
    customElement({name: "witful-searchbar", shadowOptions: null})
  ], WitfulSearchBar);
};
