const placeholders = [
  "Search for notes, people, attachments, and the meaning of life",
  "What do you want to find today?",
  "Your note isn't lost, it's just wandering",
  "Type some words. Witful will do the rest.",
  "Now, where did I put that...?",
  "Search for that thing you said to that someone sometime",
  "Search far and wide",
  "Witful search. Unlimited retries with the power of Backspace™ technology.",
  "Search so good, your memory will get noticeably worse.",
  "That moment where you realize what you're searching for is in your hands",
  "As long as one keeps searching, the answers will come",
  "I want to live in a world where searching my notes burns calories",
  "Seek and ye shall discover",
  "Traverse the archives of your productivity"
];
export const generatePlaceholder = () => placeholders[Math.floor(Math.random() * placeholders.length)];
