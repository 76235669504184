import * as IndexedDB from "../../util/indexedDB.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
export const subscribe = (elmApp) => elmApp.ports.saveSearchWeightIndex_.subscribe(saveIndex);
export const sendToElm = async (user, elmApp) => elmApp.ports.searchWeightIndexLoaded_.send(await getIndices(user.uid));
const saveIndex = async (index) => {
  const db = await IndexedDB.open();
  if (!db) {
    return;
  }
  Log.debug("saving search weight index", index);
  return db.put("searchWeightIndex", index.data, index.path).catch((err) => Log.warning("Error saving Search Weight Index to IndexedDB", {err}));
};
const emptyIndex = {updatedAt: 0, people: {}};
const getIndices = async (userId) => {
  const db = await IndexedDB.open();
  if (!db) {
    return emptyIndex;
  }
  const maybeIndex = await db.get("searchWeightIndex", `/accounts/${userId}`);
  return maybeIndex || emptyIndex;
};
