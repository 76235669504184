import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
import {firebase, firestore} from "../../../../_snowpack/link/packages/frontend/common/firebase.js";
import * as AppVersion from "./app-version.js";
import {handleFirestoreError} from "../util.js";
import firestoreUtil from "../../../../_snowpack/link/packages/frontend/common/firestore.js";
import {signOutHelper} from "./auth.js";
import {clearSettingsFromLocalStorage} from "../../util/settings.js";
export const toAccountRef = (userId) => firestore.collection("accounts").doc(userId);
export const toSubscriptionRef = (userId) => firestore.collection("subscriptions").doc(userId);
export const subscribe = (elmApp) => {
  elmApp.ports.updateUser.subscribe(async (rawFields) => {
    const fields = {
      ...rawFields,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    Log.debug("updating user fields", {fields});
    const userId = firebase.auth().currentUser?.uid;
    if (!userId) {
      Log.error("updateUser: expected logged in user");
      return;
    }
    await toAccountRef(userId).update(fields);
  });
  elmApp.ports.devUnlinkCalendar.subscribe(async ({restartOnboarding}) => {
    Log.debug("restarting onboarding");
    const user = firebase.auth().currentUser;
    if (!user) {
      Log.error("devUnlinkCalendar: expected logged in user");
      return;
    }
    const userUpdate = {
      tokens: firebase.firestore.FieldValue.delete()
    };
    if (restartOnboarding) {
      userUpdate.tutorialComplete = false;
      userUpdate.introContentPreloaded = false;
    }
    await toAccountRef(user.uid).update(userUpdate);
    if (restartOnboarding) {
      await toAccountRef(user.uid).collection("settings").doc("user").delete();
      clearSettingsFromLocalStorage();
    }
    restartOnboarding ? signOutHelper({type: "internalRoute", path: "/signup"}) : signOutHelper({type: "home"});
  });
};
export const watchUserSubscription = (elmApp, userId) => {
  const userSubscriptionRef = toSubscriptionRef(userId);
  firestoreUtil.onDocumentSnapshot(userSubscriptionRef, {
    next: (subDoc) => {
      elmApp.ports.userSubscription.send(subDoc.data() || null);
      Log.debug("userSubscription snapshot updated");
    },
    error: (err) => {
      handleFirestoreError(elmApp, userSubscriptionRef.path, err);
      Log.error("userSubscription.onSnapshot error", {
        documentPath: userSubscriptionRef.path,
        err
      });
    }
  });
  Log.debug("userSubscription subscribed");
};
export const sendToElm = async (user, elmApp) => {
  if (!user) {
    elmApp.ports.signedInStatus.send(null);
    return;
  }
  const authToken = await user.getIdToken(true).catch(logGetIdTokenError);
  setInterval(function() {
    user.getIdToken(true).then(elmApp.ports.updateUserIdToken.send).catch(logGetIdTokenError);
  }, 1e3 * 60 * 30);
  const userSubscriptionRef = toSubscriptionRef(user.uid);
  const userAccountRef = toAccountRef(user.uid);
  Log.debug("requesting user/subscription data");
  return Promise.all([userAccountRef.get(), userSubscriptionRef.get()]).then(([userDoc, subDoc]) => {
    const userData = userDoc.data();
    const hasRefreshToken = !!userData?.tokens?.refresh_token;
    const subscription = subDoc.exists ? subDoc.data() : null;
    const witfulUser = {
      id: user.uid,
      email: user.email || "",
      displayName: user.displayName || "",
      photoURL: user.photoURL || "",
      calendarIsLinked: hasRefreshToken,
      tutorialComplete: !!userData?.tutorialComplete,
      introContentPreloaded: !!userData?.introContentPreloaded,
      popupsShown: userData?.popupsShown || null,
      subscription,
      authToken: authToken || "failed-to-fetch-user-id-token",
      alerts: userData?.alerts || {}
    };
    elmApp.ports.signedInStatus.send(witfulUser);
    Log.debug("user signed in");
    AppVersion.watch(elmApp);
    watchUserSubscription(elmApp, user.uid);
  }).catch((err) => {
    Log.error("Error retrieving user account info", {
      documentPaths: [userSubscriptionRef.path, userAccountRef.path],
      err
    });
  });
};
const logGetIdTokenError = (err) => {
  Log.error("Unable to getIdToken for signed-in user", {
    err
  });
};
