import * as Analytics from "../../analytics.js";
import {gtag} from "../../google-analytics.js";
export const subscribe = (elmApp) => {
  elmApp.ports.sendAnalytic.subscribe((analytic) => {
    Analytics.logEvent(analytic.name, analytic.data);
  });
  elmApp.ports.sendGoogleAnalytic_.subscribe(({category, action, label}) => {
    const eventData = {event_category: category};
    if (label) {
      eventData.event_label = label;
    }
    gtag("event", action, eventData);
  });
};
