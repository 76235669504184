const keyUpOnly = ["Escape", "ArrowRight", "ArrowLeft"];
export const subscribe = (elmApp) => {
  document.addEventListener("keypress", (e) => {
    if (keyUpOnly.includes(e.key)) {
      return;
    }
    if (e.key === "/") {
      e.preventDefault();
    }
    elmApp.ports.keypressed.send(e);
  });
  document.addEventListener("keyup", (e) => {
    if (keyUpOnly.includes(e.key)) {
      elmApp.ports.keypressed.send(e);
    }
  });
  elmApp.ports.setSelectionRange.subscribe((data) => {
    requestAnimationFrame(() => {
      const inputEl = document.getElementById(data.id);
      if (!inputEl)
        return;
      inputEl.select();
      inputEl.setSelectionRange(data.start, data.end);
    });
  });
  elmApp.ports.openPopup.subscribe((data) => {
    window.open(data.url);
  });
  const TIMEOUT = 10 * 1e3;
  let lastTime = Date.now();
  setInterval(() => {
    const currentTime = Date.now();
    if (currentTime > lastTime + TIMEOUT + 4e3) {
      elmApp.ports.unhibernated_.send(Date.now());
    }
    lastTime = currentTime;
  }, TIMEOUT);
};
