import * as slate from "../../../../_snowpack/pkg/slate.js";
import {ActionItem, Editor} from "../../../../_snowpack/link/packages/editor/src/index.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
import {matchActionItemElements} from "../../../../_snowpack/link/packages/editor/src/modules/actionItem/matchActionItemElements.js";
import {getBlockAbove} from "../../../../_snowpack/link/packages/editor/src/utils/queries/index.js";
export const subscribe = (elmApp) => {
  elmApp.ports.toggleActionItem.subscribe((data) => {
    const updatedEditor = toggleActionItem(data);
    if (!updatedEditor)
      return;
    elmApp.ports.updateSlateDoc.send({
      noteId: data.actionItem.noteId,
      slateDoc: JSON.stringify(updatedEditor.children),
      actionItems: ActionItem.extractActionItems(updatedEditor, data.actionItem.noteId)
    });
  });
  elmApp.ports.dragStartActionItem.subscribe(({text, href, dragEvent}) => {
    const fragment = [{type: "a", href, children: [{text}]}];
    const encodedFragment = btoa(encodeURIComponent(JSON.stringify(fragment)));
    dragEvent.dataTransfer?.setData("text/plain", text);
    dragEvent.dataTransfer?.setData("application/x-slate-fragment", encodedFragment);
  });
};
export const toggleActionItem = (data) => {
  const {actionItem} = data;
  try {
    const slateDoc = JSON.parse(data.slateDoc);
    const editor = Editor.init();
    editor.children = slateDoc;
    toggleActionItemChips(editor, actionItem, data.toggledFrom);
    return editor;
  } catch (err) {
    Log.error("Slate: Toggle action item failed", {
      err,
      loc: "toggle-action-item-port"
    });
    return null;
  }
};
const toggleActionItemChips = (editor, actionItem, toggledFrom) => {
  const {id: actionItemId, noteId} = actionItem;
  const actionItemChip = ActionItem.getActionItemById(editor, actionItemId);
  if (!actionItemChip) {
    return Log.error("Expected action item chips", {noteId, actionItemId});
  }
  const blockAbove = getBlockAbove(editor, actionItemChip[1]);
  if (!blockAbove) {
    return Log.error("No block above action item", {noteId, actionItemId});
  }
  const [, blockAbovePath] = blockAbove;
  const actionItemChips = Array.from(slate.Editor.nodes(editor, {
    at: blockAbovePath,
    match: matchActionItemElements
  }));
  const completedAt = actionItem.completedAt ? null : Date.now();
  actionItemChips.forEach(([node, path]) => {
    slate.Transforms.setNodes(editor, updateActionItemNode(node, toggledFrom, completedAt), {at: path});
  });
};
const updateActionItemNode = (node, toggledFrom, completedAt) => {
  const completedIn = completedAt ? toggledFrom : null;
  if (node.type === "action-item-chip") {
    return {
      ...node,
      data: {...node.data, completedAt}
    };
  }
  return {
    ...node,
    data: {...node.data, completedIn, completedAt}
  };
};
