import * as Log from "./logging.js";
import {firebase} from "./firebase.js";
const SUBSCRIBED_SNAPSHOTS = {};
const onDocumentSnapshot = (ref, observer) => {
  SUBSCRIBED_SNAPSHOTS[ref.path] = ref.onSnapshot(observer);
};
const onCollectionSnapshot = (ref, observer) => {
  SUBSCRIBED_SNAPSHOTS[ref.path] = ref.onSnapshot(observer);
};
const unsubscribe = (ref) => {
  const unsubscribeFunction = SUBSCRIBED_SNAPSHOTS[ref.path];
  if (unsubscribeFunction)
    unsubscribeFunction();
  Log.debug(`unsubscribed from ${ref.path}`);
  delete SUBSCRIBED_SNAPSHOTS[ref.path];
};
const unsubscribeAll = () => {
  Object.entries(SUBSCRIBED_SNAPSHOTS).forEach(([path, unsubscribeFunction]) => {
    unsubscribeFunction();
    Log.debug(`unsubscribed from ${path}`);
    delete SUBSCRIBED_SNAPSHOTS[path];
  });
};
const fieldTimestampHelper = (fieldName) => ({...obj}) => {
  const timestamp = obj[fieldName];
  if (timestamp === void 0) {
    return obj;
  } else if (timestamp === null) {
    obj[fieldName] = firebase.firestore.FieldValue.serverTimestamp();
  } else if ("seconds" in timestamp && "nanoseconds" in timestamp) {
    obj[fieldName] = new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds);
  } else {
    Log.error("Not a recognized Elm Data.Timestamp", {
      timestamp,
      fieldName,
      doc: obj
    });
  }
  return obj;
};
const fieldCreatedAt = fieldTimestampHelper("createdAt");
const fieldUpdatedAt = fieldTimestampHelper("updatedAt");
export default {
  onDocumentSnapshot,
  onCollectionSnapshot,
  unsubscribe,
  unsubscribeAll,
  fieldCreatedAt,
  fieldUpdatedAt
};
