import * as IDB from "../../../_snowpack/pkg/idb.js";
import * as Log from "../../../_snowpack/link/packages/frontend/common/logging.js";
import config from "../../../_snowpack/link/packages/frontend/common/config.js";
const dbName = `witful/${config.firebase.projectId}`;
const dbVersion = 2;
export const open = async () => {
  if (!("indexedDB" in window)) {
    Log.warning("IndexedDB not supported", null);
    return;
  }
  return IDB.openDB(dbName, dbVersion, {
    upgrade(db, oldVersion) {
      if (oldVersion < 1) {
        Log.info("Witful IndexedDB: Create 'searchIndices'", {version: 1});
        db.createObjectStore("searchIndices");
      }
      if (oldVersion < 2) {
        Log.info("Witful IndexedDB: Create 'searchWeightIndex'", {
          version: 2
        });
        db.createObjectStore("searchWeightIndex");
      }
    }
  });
};
