import * as IndexedDB from "../../util/indexedDB.js";
import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
export const subscribe = (elmApp) => {
  elmApp.ports.saveSearchIndex.subscribe((indexData) => saveIndex(indexData));
};
export const sendToElm = async (user, elmApp) => {
  elmApp.ports.searchIndicesLoaded.send(await getIndices(user.uid));
};
const saveIndex = async (searchIndex) => {
  const db = await IndexedDB.open();
  if (!db) {
    return;
  }
  Log.debug("saving search index", searchIndex);
  return db.put("searchIndices", searchIndex.data, searchIndex.path).catch((err) => Log.warning("Error saving Search Index to IndexedDB", {err}));
};
const getIndices = async (userId) => {
  const db = await IndexedDB.open();
  if (!db) {
    return {notes: null, people: null};
  }
  const notes = await db.get("searchIndices", `/accounts/${userId}/notes`) || null;
  const people = await db.get("searchIndices", `/accounts/${userId}/people`) || null;
  return {notes, people};
};
